import React from 'react';
import '../styles/Partenaires.css';
import 'react-slideshow-image/dist/styles.css';
import Footer from '../components/Footer';
import logoadn41 from '../images/Adn41.jpg';
import logotoutourisme from '../images/logoToutourisme.png';
import OuvrirFenetrePartenaire from '../components/OuvrirFenetrePartenaire';
import OuvrirFenetrePartenaireAvecOption from '../components/OuvrirFenetrePartenaireAvecOption';

const Partenaires = () => {
    return (
        <div className="partenaires">
            
            <h2 className='LigneH2t' > - </h2>
            
            <h2 className='LigneH2c' >Nos Partenaires</h2>
            
            <div className="partenaires listePart">
           
            <img src={logoadn41} className='listePart logo-adn41' alt='logo Adn41' />

            <OuvrirFenetrePartenaire 
                imageUrl="ImgPartenaires/logoCdt.png" 
                sitePartenaire="https://association-au-coeur-de-troo.jimdosite.com"
                classPartenaire='listePart logo-cdt'
                altPartenaire='logo Coeur de Troo'
                /> 
            
            <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/logoEsat.jpg" 
                sitePartenaire="https://www.esat-apf-jeanmuriel.fr/"
                classPartenaire='logo-esat'
                altPartenaire='Esat de lunay'
                /> 

            <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/logoHistorius.jpg" 
                sitePartenaire="https://www.historius-montoire.fr/"
                classPartenaire='logo-historius'
                altPartenaire='logo HistOrius'
                /> 
            
            <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/logoLoirDecouvertes.jpg" 
                sitePartenaire="https://loirdecouvertes.fr/"
                classPartenaire='logo-ld'
                altPartenaire='Loir Découvertes'
                /> 
            
            <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/PoleImage41.png" 
                sitePartenaire="https://poleimage41.fr/"
                classPartenaire='logo-pi41'
                altPartenaire='Logo Pole Image 41'
                /> 
            
            <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/logoOtv.jpg" 
                sitePartenaire="https://www.vendome-tourisme.fr/"
                classPartenaire='logo-otv'
                altPartenaire='logo Office Tourisme Vendome'
                /> 
            
            <OuvrirFenetrePartenaireAvecOption
                imageUrl="/ImgPartenaires/logoTerritoiresVendomois.jpg" 
                sitePartenaire="https://www.territoiresvendomois.fr/"
                classPartenaire='logo-tv'
                altPartenaire='Logo Territoires Vendomois'
                /> 
                        
            <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/logo-ttvl.png" 
                sitePartenaire="https://www.ttvl.fr/"
                classPartenaire='logo-ttvl'
                altPartenaire='logo Train Touristique de la Vallée du Loir'
                /> 
            
            <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/logoToutourisme.png" 
                sitePartenaire="https://toutourismefrance.jimdofree.com/"
                classPartenaire='logo-toutourisme'
                altPartenaire='Logo Toutourisme'
                /> 

            </div>

            <h2 className='LigneH2t' > - </h2>
            <h2 className='LigneH2t' > - </h2>
            <Footer />
            
        </div>
    );
};

export default Partenaires;