import React from 'react';
import { Slide } from 'react-slideshow-image';
import img1 from '../images/1.jpg';
import img2 from '../images/2.jpg';
import img3 from '../images/3.jpg';
import img4 from '../images/4.jpg';
import img5 from '../images/5.jpg';
import imgNA from '../images/NA.png';
import '../styles/Diaporama.css';
import 'react-slideshow-image/dist/styles.css';


const proprietes = {
    duration: 4000,
    transitionDuration: 400,
    infinite: true,
    indicators: true,
    arrows: true
}


const Diaporama = () => {
    return (
        <div className="slide-container">
            <Slide {...proprietes}> 
  {/*               <div className="slide1">
                    <div>
                        <img src={imgNA} alt="Gare de Troo 2400 2100" />
                    </div>
                </div> */}
                <div className="each-slide">
                    <div>
                        <img src={img1} alt="Gare de Troo 2400 2100" />
                    </div>
                </div>
                <div className="each-slide">
                    <div>
                        <img src={img2} alt="Gare de Troo 2100" />
                    </div> 
                </div>
                {/* <div className="each-slide">
                    <div>
                        <img src={imgNA} alt="Gare de Troo 2400 2100" />
                    </div>
                </div> */}
                <div className="each-slide">
                    <div>
                        <img src={img3} alt="Gare de Troo Pierres" loading="lazy" />
                    </div>
                </div>
                <div className="each-slide">
                    <div>
                        <img src={img4} alt="Gare de Troo Modelisme" loading="lazy" />
                    </div>
                </div>
                <div className="each-slide">
                    <div>
                        <img src={img5} alt="Gare de Troo DU65" loading="lazy" />
                    </div>
                </div>
            </Slide>
    </div>
    );
};

export default Diaporama;