import React from 'react';
import '../../styles/BoutiqueDetail.css';
import OuvrirFenetrePartenaireSansImg from '../../components/OuvrirFenetrePartenaireSansImg';
import OuvrirFenetrePartenaire from '../../components/OuvrirFenetrePartenaire';


import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const SetsDetail = () => {
    
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Sets de table</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='ColonBtqDetail'>
                <div className='ColonBtqDetail'>
                    <h3 className='Mag'> </h3>
                    <OuvrirFenetrePartenaireSansImg
                    sitePartenaire="https://www.esat-apf-jeanmuriel.fr/"
                    texteBoutonPartenaire="Set de table recto/verso créé en collaboration avec l'Esat Jean Muriel de Lunay"
                    /> 
                    <h3 className='Mag'> </h3>
                    <h3 className='Mag'> </h3>
                    <OuvrirFenetrePartenaire 
                    imageUrl="/ImgPartenaires/SetEsat.jpg" 
                    sitePartenaire="https://www.esat-apf-jeanmuriel.fr/"
                    classPartenaire='ImagBtqDetail3'
                    altPartenaire='Esat Lunay'
                    />     
                
                </div>        
            </div>
        
            
                 
            <Footer />
            
        </div>
    );
};

export default SetsDetail;