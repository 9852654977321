import React from 'react';
import '../../styles/BoutiqueDetail.css';
import OuvrirFenetrePartenaireSansImg from '../../components/OuvrirFenetrePartenaireSansImg';
import OuvrirFenetrePartenaire from '../../components/OuvrirFenetrePartenaire';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const MugsDetail = () => {
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Mug</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
                    <h3 className='Mag'> </h3>
                    <OuvrirFenetrePartenaireSansImg
                    sitePartenaire="https://www.esat-apf-jeanmuriel.fr/"
                    texteBoutonPartenaire="Mug créé en collaboration avec l'Esat Jean Muriel de Lunay"
                    /> 
                    <h3 className='Mag'> </h3>
                    <h3 className='Mag'> </h3>
                    <OuvrirFenetrePartenaire 
                    imageUrl="/ImgPartenaires/Mug3.png" 
                    sitePartenaire="https://www.esat-apf-jeanmuriel.fr/"
                    classPartenaire='ImagBtqMag'
                    altPartenaire='Esat Lunay'
                    />     
                
            </div>
        
            <Footer />     
            
            
        </div>
    );
};

export default MugsDetail;