import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class OuvrirFenetrePartenaireAvecOption extends Component {
    constructor(props) {
        super(props);
            this.imageUrl= props.imageUrl;
            this.sitePartenaire= props.sitePartenaire;
            this.classPartenaire= props.classPartenaire;
            this.altPartenaire= props.altPartenaire;
    }

      
    RedirectionSite = () => {    
        window.open(this.sitePartenaire, "_blank",'noopener,noreferrer');
    };

    render() {
    return (
        <div>
            
            <img 
            src={this.imageUrl}
            alt={this.altPartenaire}
            onClick={this.RedirectionSite}
            className={this.classPartenaire}
            />
        </div>
    );
    }

};

export default OuvrirFenetrePartenaireAvecOption;