import React from 'react';
import '../../styles/BoutiqueDetail.css';
import MagAgt from '../../images/MagAgt.jpg';
import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';
import OuvrirFenetrePartenaireSansImg from '../../components/OuvrirFenetrePartenaireSansImg';
import OuvrirFenetrePartenaire from '../../components/OuvrirFenetrePartenaire';

const MagnetsDetail = () => {
    
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Magnets</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='ColonBtqDetail'>
                <h2 className='LigneH2tBtq' > - </h2>
                <h3 className='H4BtqDetail'>Magnets créés par l'Amicale </h3>
                <img className='ImagBtqMagSansPointer' src={MagAgt} alt="Magnets Amicale" />
            </div>
            <div className='ColonBtqDetail'>                       
                <h2 className='LigneH2tBtq' > - </h2>
                <OuvrirFenetrePartenaireSansImg
                    sitePartenaire="https://association-au-coeur-de-troo.jimdosite.com"
                    texteBoutonPartenaire="Magnets : Coeur de Trôo"
                /> 
                <h4 className='LigneH4tBtq' > - </h4>
                <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/MagTT.jpg" 
                sitePartenaire="https://association-au-coeur-de-troo.jimdosite.com"
                classPartenaire='ImagBtqMag'
                altPartenaire='Magnets Coeur de Troo'
                /> 
            </div>
            <Footer />
        </div>
    );
};

export default MagnetsDetail;