import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';

class OuvrirFenetrePartenaireSansImg extends Component {
    constructor(props) {
        super(props);
            this.sitePartenaire= props.sitePartenaire;
            this.texteBoutonPartenaire= props.texteBoutonPartenaire;
    }

      
    MyRedirectionSite = () => {    
        window.open(this.sitePartenaire, "_blank");
    };

    render() {
    return (
        <div>
                    
            <Button variant="primary" className="BtnBtq" onClick={this.MyRedirectionSite}  >
            {this.texteBoutonPartenaire}
            </Button>
        </div>
    );
    }

};

export default OuvrirFenetrePartenaireSansImg;