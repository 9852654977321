import React from 'react';
import '../../styles/Boutique.css';
import Livres1 from '../../images/Livres1.jpg';
import Livres2 from '../../images/Livres2.jpg';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const LivresDetail = () => {
    
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Nos Livres</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
            <div className='BtqDetail ColonBtqDetail'>
                <h3 className='BtqDetail ColonBtqDetail H4BtqDetail'>Voici un aperçu des livres que vous trouverez dans notre boutique</h3>
            
                <h3 className='BtqDetail ColonBtqDetail H3BtqDetail'>- Sur l'histoire de la région, Touristiques, Sur les autorails,...</h3>
                <h2 className='LigneH2tBtq' > - </h2>
                
            </div>
            
            <div className='BtqDetail ColonBtqDetail'>
                <img className='BtqDetail ColonBtqDetail ImagBtqDetailLivres' src={Livres1} alt="Cartes de la région" />
                <img className='BtqDetail ColonBtqDetail ImagBtqDetailLivres' src={Livres2} alt="Cartes de la région" />
            </div>
            <Footer />
        </div>
    );
};

export default LivresDetail;