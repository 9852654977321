import React from 'react';
import '../../styles/BoutiqueDetail.css';

import LivreRonsardRecto from '../../images/LivreRonsardRecto.jpg';
import LivreRonsardVerso from '../../images/LivreRonsardVerso.jpg';
import LivreRonsardSFRecto from '../../images/LivreRonsardSFRecto.jpg';
import LivreRonsardSFVerso from '../../images/LivreRonsardSFVerso.jpg';

import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';

const Nouveautes = () => {
    // const navigate= useNavigate()
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Nouveautés 2024</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            
           
            {/* <div className='BtqDetail ColonBtqDetail'>
                <h3 className='H4BtqDetail'>Miel ADN41</h3>
                <h3 className='H4BtqDetail'>Miel de fleurs de printemps. Pôt de 500grs</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetail' src={MielFleurs} alt="Miel Fleur" />
                </div>
                <h3 className='H4BtqDetail'>Miel récolté au château de Rochambeau. Pôt de 250grs</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetail' src={MielRoch} alt="Miel Fleur" />
                </div>
            </div> */}
            <div className='BtqDetail ColonBtqDetail'>
                <h1 className='BtqDetail H2espBtqDetail'> </h1>
                
                    <h3 className='H5BtqDetail'>2024 - 500 ans de la naissance de Ronsard </h3>
                
                    <h3 className='H5BtqDetail'>Nouveaux Livres sur Ronsard et son oeuvre. Ces 2 livres sont complémentaires. Le premier retrace la vie de Ronsard et le second décrit l'histoire de son oeuvre de sa mort jusqu'en 2024.</h3>
                    
                    <h3 className='H5BtqDetail'>Le premier de Severine Fraisse</h3>
                    <img className='ImagBtqLM' src={LivreRonsardSFRecto} alt="Livre Ronsard" />
                    <img className='ImagBtqLM' src={LivreRonsardSFVerso} alt="Livre Ronsard" />
                
                <div className='Mag'>
                <h3 className='H5BtqDetail'>Le second de Jean Jacques Loisel </h3>
                    <img className='ImagBtqLM' src={LivreRonsardRecto} alt="Livre Ronsard" />
                    <img className='ImagBtqLM' src={LivreRonsardVerso} alt="Livre Ronsard" />
                </div>

                
            </div>
            
            <div className='Mag'>
                    <h1 className='BtqDetail H2espBtqDetail'>  </h1>
                    <h3 className='H5BtqDetail'>Nos autres livres sur Ronsard restent disponibles </h3>
                    
                
                    
                </div>
            <div className='Mag'>
            <Footer />
            </div>
        </div>
    );
};

export default Nouveautes;