import React from 'react';
import '../../styles/BoutiqueDetail.css';
import CartesTTVL from '../../images/CartesTTVL.jpg';
import CartesRegion from '../../images/CartesRegion.jpg';
import ImgAccueilBoutique from '../../components/ImgAccueilBoutique';
import Footer from '../../components/Footer';
import OuvrirFenetrePartenaireSansImg from '../../components/OuvrirFenetrePartenaireSansImg';
import OuvrirFenetrePartenaire from '../../components/OuvrirFenetrePartenaire';

const CartesDetail = () => {
    return (
        <div className='BtqDetail'>
            <ImgAccueilBoutique />
            <h1 className='BtqDetail H1BtqDetail'> </h1>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>
            <h2 className='BtqDetail H2BtqDetail'> Nos Cartes postales</h2>
            <h1 className='BtqDetail H2espBtqDetail'> </h1>

            <h3 className='H4BtqDetail'>Voici un aperçu des cartes postales que vous trouverez dans notre boutique</h3>
            <h2 className='LigneH2tBtq' > - </h2>
            
            <div className='ColonBtqDetail'>
                <h3 className='H3BtqDetail'>Cartes du train touristique (TTVL)</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetailSansPointer' src={CartesTTVL} alt="Cartes TTVL" />
                </div> 
            </div>
        
            <div className='ColonBtqDetail'>
                <h3 className='H3BtqDetail'>Cartes de la région</h3>
                <div className='Mag'>
                    <img className='ImagBtqDetailSansPointer' src={CartesRegion} alt="Cartes de la région" /> 
                </div>  
            </div>

            <div className='ColonBtqDetail'>
                <h2 className='LigneH2tBtq' > - </h2>
                <OuvrirFenetrePartenaireSansImg
                    sitePartenaire="https://www.yvesducourtioux.fr/"
                    texteBoutonPartenaire="Cartes de collection de la région : Yves Ducourtioux"
                /> 
                
                <OuvrirFenetrePartenaire 
                imageUrl="/ImgPartenaires/CartesDucourtioux.jpg" 
                sitePartenaire="https://www.yvesducourtioux.fr/"
                classPartenaire='ImagBtqDetail'
                altPartenaire='Cartes Ducourtioux'
                /> 
                <h2 className='LigneH2tBtq' > - </h2>
            </div>  
                                  
            
            <Footer />
        </div>
    );
};

export default CartesDetail;